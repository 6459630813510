import $ from "jquery"

$(window).scroll(function() {
  /*	console.log($(this).scrollTop());*/
  if ($(window).width() >= 480) {

    if ($(this).scrollTop() >= 300) {

      $("header nav").addClass("fixed");


    } else {

      $("header nav").removeClass("fixed");

    }

  }
});